import React, { Component } from "react";
import Navbar from "../navBar/Navbar";
import "./errorBoundary.scss"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Navbar />
          <div
            className="errorBoundary"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <h1>Something went wrong.</h1>
            <div
              onClick={() => window.location.reload()}
              style={{
                color: "#fff",
                cursor: "pointer",
                fontFamily: "Helvetica",
                marginTop: 10,
                backgroundColor: "#b80303",
                padding: 10,
                borderRadius: 5,
                fontSize: 18,
              }}
            >
              Refresh Page
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

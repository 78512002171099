import React from "react";

import "./interactionsLoading.scss";

const InteractionsLoading = () => {
  return (
    <div className="interactionsLoading">
      <div className="loadingCard">
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
        <div className="isloading">
          <div className="left"></div>
          <div className="right"></div>
        </div>
      </div>
    </div>
  );
};

export default InteractionsLoading;

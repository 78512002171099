import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { DarkModeContextProvider } from "./context/darkModeContext";
import { AuthContextProvider } from "./context/authContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SocketProvider } from "./context/SocketContext";
import { DataContextProvider } from "./context/dummyDataContext";
//import "./main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <SocketProvider>
            <DataContextProvider>
              <App />
            </DataContextProvider>
          </SocketProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </DarkModeContextProvider>
  </React.StrictMode>
);

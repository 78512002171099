import axios from "axios";
import React, {  useState } from "react";

const Compress = () => {
  const [postImage, setPostImage] = useState(null); // Initialize to null

  const compressImage = async (e) => {
    e.preventDefault();

    if (!postImage) {
      console.log("No image selected");
      return; // Exit if no image is selected
    }

    try {
      const formData = new FormData();
      formData.append("compressedImage", postImage); // Ensure this matches the backend field name

      const res = await axios.post(
        `${process.env.REACT_APP_URL}/compressedImage/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.log("Error processing image:", error);
    }
  };

  return (
    <div>
      <form encType="multipart/form-data" onSubmit={compressImage}>
        <div className="input-field">
          {postImage && (
            <img
              className="postImagePreview"
              alt=""
              src={URL.createObjectURL(postImage)}
              style={{ width: 300, height: 300 }}
            />
          )}
        </div>
        <div className="action-field">
          <div className="document-field">
            <div className="upload-img">
              <input
                type="file"
                name="postImage"
                id="addImg"
                onChange={(e) => setPostImage(e.target.files[0])}
              />
            </div>
          </div>
          <button type="submit">Compress Image</button>
        </div>
      </form>
    </div>
  );
};

export default Compress;

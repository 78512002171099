import React from "react";
import "./profileEditLoading.scss";

const ProfileEditLoading = () => {
  return (
    <div className="profileEditLoading">
      <div className="theLoading">
        <div className="top"></div>
        <div className="img">
          <div></div>
          <div></div>
        </div>

        <div className="others">
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
          <div className="inputs"></div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditLoading;

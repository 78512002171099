export const COLORS = {
    light: {
        primary: "#b80303",
        themeSwitch: "#087ca7",
        secondary: "#05b2dc",
        textColor: "#000",
        bg: "#fff",
        logo: "#b80303",
        bgSoft: "#dce3e6",
        textColorSoft: "#555",     
        border: "lightgray",
        borderTwo: "#424242",
        cardColor: "#fff",
        iconsColor: "#087ca7",
        iconsHoverColor: "whitesmoke",
        addPost: "#dce3e6",
        inputColor: "#868686",
        hoverColor: "whitesmoke",
        buttonBg: "#b80303",
        buttonBgHover: "#970404",
        white: "#ffffff",
        gray: "#a8a8a8",
        red: "#df0404",
        links: "#087ca7",
        linkHover: "#04bcff",
        postHover: "#fcfbfb",
        chatLeftBg: "#b1b1b1",
        chatOutgoing: "teal",
        notifBg: "#e3eefd",
        postFollow: "#0602c5",
        blueTheme: "#0602c5",
        tagsTheme: "#0602c5",
        postImageUpload: "#0602c5"
    },
      dark: {
        primary: "#b80303",
        themeSwitch: "#ffffff",
        secondary: "#05b2dc",
        textColor: "whitesmoke",
        bg: "#1a1a20",
        logo: "#fff",
        bgSoft: "#1a1a20",
        textColorSoft: "lightgray",
        border: "#29292b",
        borderTwo: "#7a7a7c",
        cardColor: "#202024",
        iconsColor: "#087ca7",
        iconsHoverColor: "#518397",
        addPost: "#343235",
        inputColor: "lightgray",
        hoverColor: "#524e4e",
        buttonBg: "#b80303",
        buttonBgHover: "#970404",
        white: "#ffffff",
        gray: "#a8a8a8",
        red: "#df0404",
        links: "#087ca7",
        linkHover: "#818181",
        postHover: "#4e4e4e7a",
        chatLeftBg: "#555",
        chatOutgoing: "teal",
        notifBg: "#34393f",
        blueTheme: "#0602c5",
        tagsTheme: "#77acf1",
        postFollow: "#ffffff",
        postImageUpload: "#ffffff"
      },
}

const appTheme = { COLORS };

export default appTheme;
import { createContext, useContext, useEffect, useState } from "react";
// import axios from "axios";
import { makeRequest } from "../axios";
import { AuthContext } from "./authContext";

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const { loggedInUser, authToken } = useContext(AuthContext);
  const [placeholderPosts, setPlaceholderPosts] = useState([]);

  useEffect(() => {
    const ONE_HOUR = 1 * 60 * 60 * 1000;

    const updateDummyPost = async () => {
      const res = await makeRequest.get("posts/placeholderPosts", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      localStorage.setItem("posts", JSON.stringify(res.data));
      localStorage.setItem("lastUpdate", Date.now().toString());
    };

    const storedPosts = localStorage.getItem("posts");
    const lastUpdate = localStorage.getItem("lastUpdate");
    const currentTime = Date.now();

    if (
      storedPosts &&
      lastUpdate &&
      currentTime - parseInt(lastUpdate) < ONE_HOUR
    ) {
      setPlaceholderPosts(JSON.parse(storedPosts));
    } else if (loggedInUser) {
      updateDummyPost();
    }

    // Set a timeout to update localStorage posts every one Hour
    const timedOut = setTimeout(updateDummyPost, ONE_HOUR);

    // Cleanup the timeout on unmount
    return () => clearTimeout(timedOut);
  }, [authToken, loggedInUser]);

  return (
    <DataContext.Provider value={{ placeholderPosts }}>
      {children}
    </DataContext.Provider>
  );
};
